import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/array-comprehensions",
  "date": "2015-07-22",
  "title": "ARRAY COMPREHENSIONS",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Array comprehension is creating a list B based on another list A, where each entry of B is the result of a custom operation performed on the items of list A."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Comprehension prevents us from using manual iteration which is error-prone and tedious.`}</p>
    <p>{`JavaScript(ES5) already has similar built-in `}<inlineCode parentName="p">{`map`}</inlineCode>{` and `}<inlineCode parentName="p">{`filter`}</inlineCode>{` functions.`}</p>
    <Message type="info" title="Map & Filter" content="Best way to understand Array comprehensions is by comparing it with map and filter functions." mdxType="Message" />
    <h2>{`Array.prototype.map`}</h2>
    <p>{`This built-in function returns a new array which is the result of applying a callback function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`[1, 2, 3].map(function (i) {
  return i * i;
}); // [1, 4, 9]

[100,012,8000].map(String).join('-'); // "100-012-8000"
`}</code></pre>
    <p>{`We could create an array with `}<inlineCode parentName="p">{`Number`}</inlineCode>{` in-built function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Array.apply(null, Array(5))
     .map(Function.prototype.call.bind(Number)); // [0, 1, 2, 3, 4]

Array.apply(null, {length: 5})
     .map(Number.call, Number); // [0, 1, 2, 3, 4]
`}</code></pre>
    <h2>{`Array.prototype.filter`}</h2>
    <p>{`This function lets you `}<inlineCode parentName="p">{`include`}</inlineCode>{` or `}<inlineCode parentName="p">{`exclude`}</inlineCode>{` some entries of the array based on some certain criteria.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`[1,0,2,4,-8,6].filter(function(i) {
  return i < 3
}); // [1, 0, 2, -8]
`}</code></pre>
    <Message type="info" title="Filters in practice" content="Practical/real world implementations of `filters` can be - find the students who have passed a course." mdxType="Message" />
    <h2>{`Array Comprehension in ES6`}</h2>
    <p>{`Part of Firefox but not available in ES5, array comprehensation is now officially a part of ES6.
Easiest way to understand Array Comprehension is by comparing it with `}<inlineCode parentName="p">{`map`}</inlineCode>{` and `}<inlineCode parentName="p">{`filter`}</inlineCode>{`.
Let's take the previous map example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`[1, 2, 3].map(function (i) {
  return i * i;
}); // [1, 4, 9]

[for(i of [1, 2, 3]) i * i]; // [1, 4, 9]
`}</code></pre>
    <p>{`Here array comprehension uses `}<inlineCode parentName="p">{`of`}</inlineCode>{` clause to iterate the sequence of the array.`}</p>
    <p>{`Filtering using array comprehension is straightforward.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`[1,0,2,4,-8,6].filter(function(i) {
  return i < 3
}); // [1, 0, 2, -8]

[for (i of [1,0,2,4,-8,6]) if(i < 3)];
`}</code></pre>
    <p>{`As you can see, `}<inlineCode parentName="p">{`if`}</inlineCode>{` operates as the `}<strong parentName="p">{`filter`}</strong>{`" to produce the sequence we need.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      